import React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';

import './Login.css'

const provider = {
    custom: 'azure-tuiad.net'
}
function handleSignInClick() {
    signInWithRedirect({ provider })
}

const Login = () => {
    return (
        <div>
            <div className="login-page">
                <div className="login-form">
                    <img
                        src="/img/logo.png"
                        height="150"
                        className="d-inline-block align-center"
                        alt="logo"
                    />
                    <button onClick={handleSignInClick}>Sign in with SSO</button>
                </div>
            </div>
        </div>
    );
};

export default Login;